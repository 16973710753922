.tabs-container {
	width: 100%;
	overflow: hidden;
}

.tabs {
	display: flex;
	align-items: center;
}



.tab-content {
	clear: both;
}


.add-tab {
	margin-left: auto;
	color :white;/* Pushes the "Add Tab" button to the right */
	
}
.more-tab {
	margin-right: 500px;
	margin-left: 20px;
	color: white; /* Adjust the margin as needed */
}

