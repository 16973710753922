body {
    margin: 0;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face { font-display: swap; font-family: 'Asap'; font-style: normal; font-weight: 400; src: url(./css/fonts/asap-v26-latin-regular.woff2) format('woff2') }
@font-face { font-display: swap; font-family: 'Asap'; font-style: normal; font-weight: 600; src: url(./css/fonts/asap-v26-latin-600.woff2) format('woff2') }
@font-face { font-display: swap; font-family: 'Asap'; font-style: normal; font-weight: 700; src: url(./css/fonts/asap-v26-latin-700.woff2) format('woff2') }

html, body, p, ol, ul, li, th, td, div, dl, dt, dd, blockquote, input,button, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
    font-family: Asap, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

select {
    padding: 4px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wizardfonts {
    font-weight: 600;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    background-color: white !important;
}
#root {
    position: absolute;
    z-index: 401;
    width: 100%;
    background-color: #eee;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-form legend {
    float: unset !important;
    width: auto !important;
    overflow: hidden !important;
    display: block !important;
    padding: 0 !important;
    height: 11px !important;
    font-size: 0.75em !important;
    visibility: hidden !important;
    max-width: 100% !important;
    -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms !important;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms !important;
    white-space: nowrap !important;
}

:where(.css-txh9fw).ant-form legend {
    float: unset !important;
    width: auto !important;
    overflow: hidden !important;
    display: block !important;
    padding: 0 !important;
    height: 11px !important;
    font-size: 0.75em !important;
    visibility: hidden !important;
    max-width: 100% !important;
    -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms !important;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms !important;
    white-space: nowrap !important;
}
