.container {
	display: flex;
}

.column {
	flex: 1;
	margin: 0 10px;
	text-align: left; /* Adjust margin as needed */
}

.row {
	margin-bottom: 10px;
	 /* Adjust margin as needed */
}


/* Add this to your StationStatus.css file */

/* Scrollbar styling for WebKit-based browsers (Chrome, Safari, etc.) */
.StationsStatus {
	overflow-y: auto; /* Ensures that the container has a vertical scrollbar */
	max-height: 130px; /* Set maximum height for the scrollable container */
	min-width: 130px;
  }
  
  .StationsStatus::-webkit-scrollbar {
	width: 8px; /* Width of the entire scrollbar */
  }
  
  .StationsStatus::-webkit-scrollbar-track {
	background: #f1f1f1; /* Color of the scrollbar track */
  }
  
  .StationsStatus::-webkit-scrollbar-thumb {
	background-color: green; /* Color of the scrollbar handle */
	border-radius: 10px; /* Rounded corners on the scrollbar handle */
	border: 2px solid #ffffff; /* Adds a border around the scrollbar handle for better visibility */
  }
  
  .StationsStatus::-webkit-scrollbar-thumb:hover {
	background-color: darkgreen; /* Darker green color when hovering over the scrollbar handle */
  }
  