/* WelcomePage.css */

.welcome-container {
    background-color: #ffffff; /* White background color for the entire page */
    padding: 20px; /* Add padding for better spacing */
}

.header {
    width: 100%;
    
}

.full-width-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: #f5f5f5; /* Grey background color for the content box */
    border-radius: 8px; /* Optional: Add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle box shadow */
   
    
}

.navigation-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    background-color: #ffffff;
}

.navigation-links li {
    margin: 0;
}

.navigation-links a {
    text-decoration: none;
    color: #000; /* Black text color */
    font-weight: bold;
    padding: 10px 20px;
    display: block;
}

.navigation-links a:hover {
    background-color: #4caf50;
    color: #fff; /* White text color on hover */
}

.login-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.login-button:hover {
    background-color: #45a049;
}

/* Additional styling for logos */
.logo {
    max-width: 100px; /* Adjust the size as needed */
    max-height: 50px; /* Adjust the size as needed */
}
/* Add this to your existing CSS file */
.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .popup-container img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .popup-container.active {
    opacity: 1;
  }
  