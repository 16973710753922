.button-link2 { height: 60px; align-items: center; text-decoration: none; text-align: center; padding: 10px; border: none; cursor: pointer; color: black; width: 100%; background-color: #FBFBFB; }

	.button-link2.active { background-color: rgba(95, 170, 57, 0.3) !important; color: black !important; }

	.button-link2.disabled { background-color: rgba(160, 160, 160, 0.5) !important; color: black !important; color: #808080 !important; }


.timeslotHeader {
	padding-left: 0.5% !important;
	width: 10% !important;
	text-align: left !important;
}
.timeSlotHeaderEnd {
	width: 2% !important;
	padding-left: 0px !important;
	text-align: left !important;
}
.timeslot { 
	width: 2% !important; 
	border-bottom: 1px solid #f0f0f0 !important;
	border-right: 1px solid #f0f0f0 !important;
	border-left: 1px solid #f0f0f0 !important;
	text-align: center; 
	padding: 0.5px  !important; 
}
	.timeslot.selected {
		width: 2% !important;
		text-align: center !important;
		background-color: rgba(95, 170, 57, 0.7);
	}

.myinput[type="checkbox"]:before { position: relative; display: block; width: 11px; height: 11px; background: #FFF; }
/*.myinput[type="checkbox"]:checked:before { position: relative; display: block; width: 11px; height: 11px; content: ""; background: #8FC374; }
*/
.myinput[type="checkbox"]:checked:after { background-image: linear-gradient(135deg, #8FC374 100%, #8FC374 100%); }
/*.myinput[type="checkbox"]:after { position: relative; display: block; left: 2px; top: -11px; width: 7px; height: 7px; border-width: 0px; border-style: solid; border-color: #B3B3B3 #dcddde #dcddde #B3B3B3; content: ""; background-image: linear-gradient(135deg, #8FC374 100%, #8FC374 100%); background-repeat: no-repeat; background-position: center; }*/
.button-link2:hover { background-color: rgba(185, 234, 159, 0.2) !important; color: black; }
input[type="checkbox"]:checked {
	background-color: #5faa39;
  }

  .MuiSwitch-colorPrimary.Mui-checked{
	color: #5faa39  !important;
  }
botton.MuiButtonBase-root {
	padding: 6px 16px !important;
}