.rounded-left {
	border-left: 1px solid #e0e0e0;
	border-top-left-radius: 30px; /* Adjust the radius to your preference */
	border-bottom-left-radius: 30px; /* Adjust the radius to your preference */
	padding: 8px;
}
/* Add rounded right border to the second cell */
.rounded-right {
	border-right: 1px solid #e0e0e0;
	border-top-right-radius: 30px; /* Adjust the radius to your preference */
	border-bottom-right-radius: 30px; /* Adjust the radius to your preference */
	padding: 8px;
}
.user-details-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.user-details {
	/* Your styles for user details (e.g., text fields, switch buttons) */
}

.membership-container {
	 /* Add spacing between user details and membership table */
}

/* Additional styles for the membership table can be applied here */
